import styled, { css } from 'styled-components';

import React from 'react';
import { TImage } from '../OxCarousel';

const StyledContainer = styled.div<{ image?: string }>(
    ({ image }) => css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(${image});
        background-size: cover;
        background-position: center;
    `
);

export const OxCarouselImage = React.forwardRef(({ image }: TImage, ref): JSX.Element => {
    return <StyledContainer image={image} ref={ref} />;
});

export const OxFluidImage = styled.div`
    height: 100%;

    .gatsby-image-wrapper {
        height: 100%;
    }
`;
