import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled(OxContainer)`
    display: flex;
    justify-content: flex-end;
`;

export const Button = styled.button(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${theme.colors.primary.main};
        ${fluidSizing([
            { prop: 'padding-left', values: [10, 10, 10] },
            { prop: 'margin-bottom', values: [20, 20, 20] }
        ])}
        svg {
            transform: rotate(-90deg);
            ${fluidSizing([
                { prop: 'width', values: [13, 13, 13] },
                { prop: 'margin-bottom', values: [3, 3, 3] }
            ])}
        }
        span {
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [8, 8, 8])}
        }
    `
);
