import * as Styled from './OxCarousel.styled';

import { OxCarouselImage, OxFluidImage } from './components/OxCarouselImage';
import React, { createRef, useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { OxIcon } from 'src/components/OxIcon';
import { TOxVideoPlayerProps } from 'src/components/OxVideoPlayer';
import { EAnimatedWrapperAnimation, OxAnimatedWrapper } from 'src/components/OxAnimatedWrapper';
import { OxImage, TFluidImages } from 'src/components/OxImage';
import { useBreakpoints } from 'src/hooks';
import { getMuxPosterLink } from 'src/utils/muxHelpers';

export enum EOxCarouselItemType {
    FluidImage = 'FLUID_IMAGE',
    Image = 'IMAGE',
    Video = 'VIDEO'
}

export type TImage = SCProps<
    'div',
    {
        image: string;
    }
> & {
    type: EOxCarouselItemType.Image;
};

export type TFluidImage = {
    fluidImages?: TFluidImages;
    type: EOxCarouselItemType.FluidImage;
};

export type TVideo = {
    type: EOxCarouselItemType.Video;
    video?: TOxVideoPlayerProps;
};

export type TOxCarouselItem = (TImage | TFluidImage | TVideo) & {
    data?: { [key: string]: unknown };
    key?: string;
};

type TProps = {
    alts: string[];
    items: TOxCarouselItem[] | TOxCarouselItem[][];
    slidesToShowMobile?: number;
    slidesToShowDesktop?: number;
    infinite?: boolean;
    carouselOptions?: Settings;
    dots?: boolean;
    arrows?: boolean;
    controlsPosition?: 'bottom-inside' | 'bottom-outside';
    imageSizes?: string;
    itemsRef?: {
        [key: number]: {
            [key: number]: React.RefObject<HTMLVideoElement>;
        };
    };
};

export const OxCarousel = ({
    alts = [],
    items = [],
    dots = false,
    arrows = false,
    controlsPosition,
    itemsRef,
    imageSizes,
    slidesToShowMobile = 1,
    slidesToShowDesktop = 1,
    infinite = true, // to match slick default
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const sliderRef = createRef<Slider>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeSubIndex, setActiveSubIndex] = useState(0);
    const device = useBreakpoints();

    const slidesToShow = device.xs ? slidesToShowMobile : slidesToShowDesktop;

    const getItemComponent = ({
        item,
        index,
        subIndex
    }: {
        item: TOxCarouselItem;
        index: number;
        subIndex: number;
    }): JSX.Element | undefined => {
        switch (item.type) {
            case EOxCarouselItemType.FluidImage:
                return (
                    <OxFluidImage>
                        <OxImage
                            alt={alts[index]}
                            fluidImages={item.fluidImages}
                            sizes={imageSizes}
                        />
                    </OxFluidImage>
                );
            case EOxCarouselItemType.Image:
                return <OxCarouselImage alt={alts[index]} {...item} />;
            case EOxCarouselItemType.Video:
                return (
                    <Styled.VideoPlayer
                        ref={itemsRef ? itemsRef[index][subIndex] : undefined}
                        playing
                        {...(item.video[index] as TOxVideoPlayerProps)}
                        fitVideo
                        usePlayButtonOverlayer={false}
                        overlayerButtonPosition="center"
                        controlsWhenPlay={false}
                        addStopButton={true}
                    />
                );
        }
    };

    const nextItem = (): void => {
        sliderRef?.current?.slickNext();
    };

    const prevItem = (): void => {
        sliderRef?.current?.slickPrev();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSubIndex((prev) => prev + 1);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <Styled.Container {...props}>
            <Slider
                ref={sliderRef}
                {...props.carouselOptions}
                afterChange={(slideIndex) => {
                    if (props.carouselOptions?.afterChange) {
                        props.carouselOptions?.afterChange(slideIndex);
                    }
                    setActiveIndex(slideIndex);
                    setActiveSubIndex(0);
                }}
                slidesToShow={slidesToShow}
                arrows={false}
                infinite={infinite}
            >
                {items.map((item: TOxCarouselItem | TOxCarouselItem[], index: number) => {
                    const items: TOxCarouselItem[] = Array.isArray(item) ? item : [item];

                    return (
                        <Styled.Item
                            slidesToShow={slidesToShow}
                            className="ox-carousel-item"
                            key={item.key ?? index}
                        >
                            {items.map((item, subIndex) => (
                                <OxAnimatedWrapper
                                    relativeBox
                                    key={subIndex}
                                    animation={EAnimatedWrapperAnimation.FadeIn}
                                    active={activeSubIndex % items.length === subIndex}
                                >
                                    {getItemComponent({ item, index, subIndex })}
                                </OxAnimatedWrapper>
                            ))}
                        </Styled.Item>
                    );
                })}
            </Slider>
            {(dots || arrows) && items.length > 1 && (
                <Styled.Controls
                    position={controlsPosition || 'bottom-outside'}
                    justDotsOrArrows={dots !== arrows}
                >
                    {arrows && (
                        <Styled.Arrows>
                            <Styled.Arrow direction="left" onClick={prevItem} isDisabled={false}>
                                <OxIcon name="arrow-3" />
                            </Styled.Arrow>
                            <Styled.Arrow direction="right" onClick={nextItem} isDisabled={false}>
                                <OxIcon name="arrow-3" />
                            </Styled.Arrow>
                        </Styled.Arrows>
                    )}
                    {dots && (
                        <Styled.Dots>
                            {items
                                .slice(0, items.length - slidesToShow + 1)
                                .map((_: TOxCarouselItem | TOxCarouselItem[], index: number) => (
                                    <Styled.Dot
                                        key={index}
                                        active={activeIndex === index}
                                        onClick={(): void => sliderRef?.current?.slickGoTo(index)}
                                    />
                                ))}
                        </Styled.Dots>
                    )}
                </Styled.Controls>
            )}
        </Styled.Container>
    );
};
