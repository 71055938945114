import * as Styled from './OxBackToTop.styled';

import { OxIcon } from '../OxIcon';
import React from 'react';
import easyScroll from 'easy-scroll';

export const OxBackToTop = (): JSX.Element => {
    const onClick = (): void => {
        easyScroll({
            scrollableDomEle: window,
            direction: 'top',
            duration: 1000,
            easingPreset: 'easeInQuad'
        });
    };

    return (
        <Styled.Container>
            <Styled.Button onClick={onClick}>
                <OxIcon name="arrow-1" />
                <span>Back to Top</span>
            </Styled.Button>
        </Styled.Container>
    );
};
