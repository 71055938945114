import styled, { css } from 'styled-components';

import { OxVideoPlayer } from '../OxVideoPlayer';
import { fluidSizing } from 'src/utils';
import { transparentize } from 'polished';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .slick-slider {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
`;

export const VideoPlayer = styled(OxVideoPlayer)`
    height: 100%;
`;

export const Items = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const Item = styled.div<{
    slidesToShow: number;
}>(
    ({ slidesToShow }) =>
        slidesToShow > 1 &&
        fluidSizing([
            { prop: 'padding-left', values: [10, 10, 10] },
            { prop: 'padding-right', values: [10, 10, 10] }
        ])
);

export const Controls = styled.div<{
    position: string;
    justDotsOrArrows: boolean;
}>(
    ({ theme, position, justDotsOrArrows }) => css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: ${justDotsOrArrows ? 'center' : 'space-between'};
        ${position === 'bottom-inside' &&
        css`
            top: -100%;
            background: ${transparentize(0.1, theme.colors.secondary.main)};
        `}
        ${fluidSizing([{ prop: 'height', values: [30, 30, 30] }])}
    `
);

export const Arrows = styled.div`
    display: flex;
    ${fluidSizing([{ prop: 'margin-left', values: [-3, -3, -3] }])}
`;

export const Arrow = styled.button<{ direction: string; isDisabled: boolean }>(
    ({ theme, direction, isDisabled }) => css`
        ${fluidSizing([{ prop: 'padding', values: [3, 3, 3] }])}
        ${direction === 'left' &&
        css`
            svg {
                transform: scale(-1);
            }
        `}
    svg {
            ${isDisabled &&
            css`
                color: ${theme.colors.basic.disabledTile};
            `}
            ${!isDisabled &&
            css`
                color: ${theme.colors.primary.main};
            `}
      ${fluidSizing([{ prop: 'height', values: [10, 10, 10] }])}
        }
    `
);

export const Dots = styled.div`
    display: flex;
`;

export const Dot = styled.button<{ active: boolean }>(
    ({ theme, active }) => css`
        border-radius: 100%;
        border: 1px solid ${theme.colors.primary.main};
        ${active &&
        css`
            background: ${theme.colors.primary.main};
        `}
        ${fluidSizing([
            { prop: 'width', values: [5, 5, 5] },
            { prop: 'height', values: [5, 5, 5] },
            { prop: 'margin-left', values: [4, 4, 4] }
        ])}
    `
);
